* {
  padding: 0;
  margin: 0;
  /* color: white !important;
  background-color: black !important; */
}
html{
  background-color: rgb(13, 42, 104);
}

@media (min-width: 440px) {
  .PDF{
    display: none;
  }
  
}
@media (max-width:440px) {
  .smallPDF{
    display: none;
  }
}

@media (max-width: 600px) {
  .mediumPDF{
    display: none;
  }
}

@media (min-width:600px) {
  .smallPDF{
    display: none;
  }
}

@media(min-width: 675px){
  .mediumPDF{
    display: none;
  }
  /* .largePDF{
    display: none
  }; */
}

 @media (max-width: 675px){
  .largePDF{
    display: none;
  }
}
/* @media (max-width: 600px) {
  .mediumPDF{
    display: none;
  }
}  */

.input{
  background-color: white;
  border-color: grey;
  padding: 0.15em;
}

.changePage{
  color: rgba(0, 0, 0, 0.596);
}

.changePage:hover{
  cursor: pointer;
}

.slide{
  transition: all 0.3s;
}

.pdf{
  display: flex;
  justify-content: center;
  align-items: center;
}


#projects{
  background-color: rgb(13, 42, 104);
  /* background-color: #0093E9; */
  background-image: linear-gradient(75deg, rgb(40, 61, 109) 0%, #485c86 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.form{
  /* background-color: white; */
  background-color: #0093E9;
  background-image: linear-gradient(170deg, rgb(115, 135, 175) 0%, #8593b1 100%);
}
#contact{
  background-color: rgb(13, 42, 104);
  /* background-color: #0093E9; */
  /* background-image: linear-gradient(270deg, rgb(13, 42, 104) 0%, #485c86 100%); */
}
#select{
  margin-left:1em;
}
#select:hover{
  cursor: pointer;;
}
.owner{
  display: flex;
  width: fit-content;
  justify-content: space-between;
}
.homeContainer{
  background-color: rgba(13, 42, 104, 0.5);
  /* background-color: #0093E9; */
  background-image: linear-gradient(160deg, rgba(13, 42, 104, 0.384) 0%, #485c8660 100%);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* width: 70em; */
  /* width: 45%; */
  width: 40em;
  height: 25em;
  color: white;
  border-radius: 0.75em;
  margin-top: 5em;
}

@media (max-width: 775px){
  .homeContainer{
    width: 99%;
    margin-top: 5em;
  }
}

@media (max-width: 750px){
  /* .homeContainer{
    width: 90%;
    margin-top: 5em;
    font-size: 0.8em;
  } */
  .homeWrapper{
    display: unset;
  }
}

@media(max-width: 670px){
  .pdf{
    /* display: none; */
    width: 10px;
  }
}

@media (max-width: 450px){
  /* .homeContainer{
    width: 99%;
    font-size: 0.75em;
    margin-top: 1em;
  } */
  .homeWrapper{
    display: unset;
  }
  #projects{
    width: 100%;
  }
  .pdf{
    width: 1em;
    /* display: none; */
  }
}
/* 
#contact{
  background-color: white;
} */

/* .homeContent{
  background-color: blue;
  margin-top: 1em;
} */

.slider {
  /* position: relative;*/
  /* height: 100vh;  */
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  width: 20em;
  border-radius: 10px;
}

.right-arrow {
  /* position: absolute;
  top: 50%;
  right: 32px; */
  font-size: 3rem;
  color: rgba(0, 0, 0, 0.356);
  /* z-index: 10; */
  cursor: pointer;
  transition: all 0.5;
}

.left-arrow {
  /* position: absolute;
  top: 75%;
  left: 32px; */
  font-size: 3rem;
  color:  rgba(0, 0, 0, 0.356);
  /* z-index: 10; */
  cursor: pointer;
  transition: all 0.5;
}

.left-arrow:hover{
  color:  rgb(0, 0, 0);
}
.right-arrow:hover{
  color:  rgb(0, 0, 0);
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  /* transform: scale(1.08); */
}

.homeWrapper{
  /* background-color: red; */
  background-image: url('./assests/images/1663707.jpg');
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  height: 45em;
  margin-top: 0%;
  font-size: 1.2rem;
}
.link{

  text-decoration: none;
  color: white;
}
#email{
  margin: 0%;
  padding: 0%;
  width: fit-content;
  margin-left: 1.25em;
  /* margin-bottom: 1.3em; */
  color: white;
  position: relative;
  position: fixed;
  top: 0.75em;
}

@media(max-width: 450px){
  #email{
    font-size: 0.9rem;
  }
}

@media(max-width: 400px){
  #email{
    font-size: 0.75rem;
  }
}

#projects{
  /* background-image: url('./assests/images/WhatsApp\ Image\ 2022-02-13\ at\ 9.29.26\ PM\(1\).jpeg'); */
  height: 60em;
}

@media (min-width: 720px) {
  .projectsContainer {
    /* border: solid; */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 1em;
    /* background-color: transparent; */
  }
}

img {
  width: 75% !important;
  max-width: 37.5em !important;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 8em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App {
  /* text-align: center; */
  justify-content: space-between;
}

.item {
  padding-top: 1em;
}
.btn {
  margin-top: 2em;
  height: 30px;
  background: rgb(196, 91, 16);
  border-radius: 5px;
  /* margin-right: 8em; */
}
input {
  width: 500px;
  /* height: 30px !important; */
}
.inputMessage {
  height: 100px;
}
.navbar {
  position: fixed; /* Set the navbar to fixed position */
  top: -15px; /* Position the navbar at the top of the page */
  width: 100%; /* Full width */
  transition: all 0.3s linear;
  background-color: red;
  z-index: 999;
  font-size: 1em;
}
menu {
  transition: all 0.3s linear;
}
hr {
  margin-top: 1em;
  transition: all 0.3s linear;
}

button:hover {
  cursor: pointer;
}
.menu {
  background-color: rgb(13, 42, 104);
  padding: 3em 0 0 0;
  color: white;
  /* background-color: red; */
}
.menu-links {
  color: black;
  text-decoration: none;
  font-size: 18px;
  font-family: 'Courier New', Courier, monospace;
  font-weight: lighter;
  padding-right: 75px;
  float: right;
}

ol {
  list-style-type: none;
  display: grid;
  width: 100%;
  transition: all 0.5s linear;
  /* background-color: blueviolet; */
}
ol .li {
  margin: 0 auto;
  margin-top: 1em;
  background-color: rgba(0, 0, 0, 0.144);
  text-align: center;
  width: 5em;
  border-radius: 9999px;
  padding: 0.3em;
  transition: all 0.3s linear;
}
ol .li:hover {
  background-color: black;
  cursor: pointer;
  transition: all 0.3s linear;
  color: white;
}
.navbar .menu ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  /* float: right; */
  position: fixed;
  top: 0.375em;
  right: 0;
}
@media (max-width: 870px) {
  .navbar .menu ul {
    display: none;
  }
}
@media (min-width: 740px) {
  ol .li {
    display: none;
  }
}

.navbar .menu ul .li {
  margin: 0 2em;
  background-color: rgba(0, 0, 0, 0.144);
  text-align: center;
  width: 5em;
  border-radius: 9999px;
  padding: 0.3em;
  transition: all 0.3s linear;
}
.navbar .menu ul .li:hover {
  background-color: black;
  cursor: pointer;
  transition: all 0.3s linear;
  color: white;
}

.link-title {
  text-decoration: none;
  /* font-size: 24px; */
  font-size: 1.25rem;
  color: black;
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
  /* padding-left: 2em; */
  transition: all 0.3s linear;
  margin-top: 0;
  margin: 1em;
  /* display: flex; */
}
@media (max-width: 400px) {
  .link-title {
    font-size: 1rem;
  }
}
.container {
  text-align: center;
  /* padding-top: 10em; */
  /* margin-top: 10em; */
  /* border: dashed; */
}

.form {
  /* width: 30em; */
  width: 90%;
  max-width: 30rem;
  padding: 10px 10px 10px 10px;
  margin: auto;
  box-shadow: 0px 9px 13px 5px;
  border-radius: 5px;
  text-align: center;
  margin-top: 1.5em;
  /* height: 26em; */
}
.submitMemo {
  padding-top: 3em;
}

input {
  width: 100%;
  border-radius: 5px;
  height: 20px;
  background: #d6d6d6;
}
textarea {
  border-radius: 5px;
  background: #d6d6d6;
  border: solid;
  border-width: 2px;
  width: 100%;
}
label {
  float: left;
  margin-bottom: 2px;
}
.fa {
  /* padding: 20px; */
  padding: 0.2em;
  /* font-size: 60px !important; */
  font-size: 5rem !important;
  /* width: 60px; */
  /* width: 50%; */
  text-align: center;
  text-decoration: none;
  /* margin: 5px 2px; */
  margin: 0.1em;
  border-radius: 50%;
}

.fa:hover {
  opacity: 0.7;
}

.fa-facebook {
  /* background: #3b5998;
  color: white; */
  color: #3b5998;
}

.fa-twitter {
  /* background: #55acee;
  color: white; */
  color: #55acee;
}

.fa-google {
  /* background: #dd4b39;
  color: white; */
  color: #dd4b39;
}
.fa-linkedin {
  /* background: #007bb5;
  color: white; */
  /* background-color: white; */
  color: #007bb5;
}
.fa-github {
  color: black;
  padding: 0;
}
.contactLinks {
  margin-top: 5em;
  margin-bottom: 5em;
  width: 100%;
  text-align: center;
}
/* h1 {
  border: solid;
} */

p {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  max-width: 500px;
  justify-content: center;
  margin: 0 auto;
  font-weight: 500;
  margin-bottom: 1em;
}

footer {
  /* position: fixed; */
  bottom: -15px;
  margin-bottom: 0;
  width: 100%;
  /* height: 40px; */
  height: 2em;
  min-height: min-content;
  /* height: min-content; */
  background: #3f547f;
  font-family: 'Courier New', Courier, monospace;
  color: #ffffff;
  font-size: 12px;
  padding-top: 1em;
  display: flex;
}
.footerTextContainer {
  /* background-color: pink; */
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.footerText {
  /* display: flex;
  align-content: right; */
  /* position: relative;
  right: 0; */
  /* background-color: blue; */
  /* width: 100%; */
  padding-right: 1em;
  padding-left: 1em;
}
button {
  margin-bottom: 2em;
  font-size: 1rem;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.288);
  color: black;
  padding: 0.25rem 0.75rem;
  border-radius: 0.25rem;
  border-color: transparent;
  text-transform: capitalize;
  font-size: 1rem;
  letter-spacing: 0.1rem;
  margin-left: 0.5em;
  margin-right: 0.5em;
  cursor: pointer;
  transition: all 0.3s linear;
}
button:hover {
  background-color: black;
  color: white;
}
.btnContainer {
  display: flex;
  /* justify-content: space-between; */
  /* width: 25%; */
  justify-content: center;
  margin: 0 auto;
}
.bar {
  /* float: right; */
  font-size: 1.5rem;
  margin: 0 1em;
  transition: all 0.3s linear;
  position: fixed;
  top: 0.35em;
  right: 0px;
}
.bar:hover {
  cursor: pointer;
  background-color: rgba(128, 128, 128, 0.397);
  border-radius: 5px;
}
@media (min-width: 870px) {
  .bar {
    display: none;
  }
}
.progHL {
  font-style: italic;
}
.contactTitle {
  margin: 1em 0 2em 0;
  color: white;
}
.navHR {
  transition: all 2s linear;
  margin-top: .75em;
  color: white;
}
.display-enter {
  opacity: 0;
  height: 0;
}
.display-enter-active {
  opacity: 0;
  height: 7.7em;
  transition: opacity 1000ms;
  transition: height 500ms;
}
.display-exit {
  opacity: 0;
  height: 7.7em;
}
.display-exit-active {
  opacity: 0;
  height: 0em;
  transition: opacity 1000ms;
  transition: height 500ms;
}
.projectTitle {
  margin-top: 1.5em;
}
.homeTitle {
  margin-bottom: 0.5em;
}
.contactHomeLink {
  background-color: rgba(0, 0, 0, 0.288);
  color: black;
  border-radius: 9999px;
  padding: 0.75em;
  transition: all 0.3s;
  background-color: white;
}
.contactHomeLink:hover {
  background-color: black;
  color: white;
  cursor: pointer;
}
.contactHomeLinkContainer {
  margin: 2em;
}
.arrow {
  content: '\2193';
  position: relative;
  top: 0.1em;
  display: none;
  font-size: 32px;
}
